import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import RobotoRegular from '../fonts/roboto/Roboto-Regular.ttf'
import RobotoLight from '../fonts/roboto/Roboto-Light.ttf'
import RobotoBold from '../fonts/roboto/Roboto-Bold.ttf'

export default createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLight});
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold});
    font-weight: bold;
  }

  body {
    font-family: 'Roboto';
  }

  p {
    line-height: 1.15;
    word-break: break-word;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
`
