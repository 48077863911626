const colors = {
  brown: '#cb6c32',
  textBrown: '#563a1c',
  gray: {
    100: '#F8F8F8',
    700: '#666',
  },
  brown: {
    300: '#d3ad68',
    700: '#cb6c32'
  }
}

const breakpoints = [
  '40em',
  '52em',
  '64em',
  '80em'
]

export default {
  colors,
  breakpoints,
  fonts: {
    heading: 'Roboto, sans-serif',
  },
  fontWeights: {
    heading: 400,
  }
}
